import React, {useEffect, useState} from 'react';
import {
    FileAddOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {Avatar, Button, Col, Flex, Row, Space} from 'antd';
import Title from "antd/es/typography/Title";

import ImigraBoost from "@/components/quickfilingComp/dashboardProfile/imigraBoost";
import SOCKit from "@/components/quickfilingComp/dashboardProfile/socKit";
import NIWKit from "@/components/quickfilingComp/dashboardProfile/niwKit";
import EB1AKit from "@/components/quickfilingComp/dashboardProfile/eb1aKit";
import "./index.scss"
import {useNavigate} from "react-router-dom";


const QuickFilingDashboard = () => {

    const [quickFormAuthorInfo ,setQuickFormAuthorInfo ] = useState(null)
    const nav= useNavigate()



    useEffect(() => {
        //todo 从服务端获取用户profile
       setTimeout(()=>{
           const data = {
               updatedAt: 1727462113458,
               name: "Ershuai Liu",
               thumbnail: "https://scholar.google.com/citations/images/avatar_scholar_128.png",
               affiliations: "Lawrence Berkeley National Laboratory",
           }
           setQuickFormAuthorInfo(data)
       }, 1000)

    }, [])


    const updateAuthorInfo = ()=> {
        nav('/quickfiling/process/profile')
    }
    const deleteAuthorInfo= ()=> {
        setQuickFormAuthorInfo(null)
    }

    return (
        <div style={{margin: '20px'}}>
            {quickFormAuthorInfo ?
                <div>
                    <Space align={"start"}>
                        <Row gutter={24}>
                            <Col span={5}>
                                <div className={'quickfiling-profile'}>
                                    <Flex vertical={true} align={'center'} >
                                        <Flex vertical={true} align={'center'}>
                                            <div>
                                                <Avatar
                                                    src={quickFormAuthorInfo?.thumbnail || ""}
                                                    size={120}
                                                    shape="square"
                                                    alt={"please login"}
                                                />
                                            </div>
                                            <br/>
                                            <h2>{quickFormAuthorInfo?.name || "Trump"}</h2>
                                            <br />
                                            <div className={'ellipsis-text-2'}
                                                 style={{maxWidth: '10rem'}}>{quickFormAuthorInfo?.affiliations||"USA"}</div>
                                        </Flex>
                                        <div style={{marginTop: 16}}>
                                            <Space direction={"vertical"}>
                                                <Button type="primary" style={{width: 120}} onClick={updateAuthorInfo}>Update Profile</Button>
                                                <Button type="primary" style={{width: 120}} onClick={deleteAuthorInfo}>Delete Profile</Button>
                                            </Space>

                                            <div style={{marginTop: 60}}>

                                                <Button type="primary" style={{width: 120}}>Rate Us</Button>
                                            </div>
                                        </div>
                                    </Flex>
                                </div>

                            </Col>
                            <Col span={18}>
                                <Space direction={"vertical"} size={'large'}>
                                    <ImigraBoost/>
                                    <SOCKit/>
                                    <NIWKit/>
                                    <EB1AKit/>
                                </Space>
                            </Col>
                        </Row>

                    </Space>
                </div>
                :
                <Flex vertical={true} justify={"center"} align="center" style={{paddingTop: 75}}>
                    <div style={{fontSize: 48}}><FileAddOutlined/></div>
                    <Title level={5}>Create your first profile to get started!</Title>
                    <br/>
                    <Button icon={<PlusOutlined/>} iconPosition={"start"} href={'/quickfiling/process/profile'}> Create
                        profile </Button>
                </Flex>
            }

        </div>
    );
};
export default QuickFilingDashboard;