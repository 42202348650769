import {createBrowserRouter} from "react-router-dom"
import Test from "@/pages/test"
import React from 'react';
import Community from "@/pages/community/community"
import Specialist from "@/pages/specialist"
import Layout from "src/components/layout";
import Create from "src/pages/create"
import Saved from "src/components/communityComp/saved";
import UserInfo from "src/components/communityComp/userInfo";
import Personal from "src/pages/personal";
import History from "src/components/communityComp/history";
import Password from "@/components/password";
import EmailVerifyPage from "@/pages/emailverify";
import NotFoundPage from "@/pages/notFount";
import HomePage from "@/pages/home";
import ChatApp from "@/pages/chat";
import Tracker from "src/components/tracker";
import QuickFiling from "@/pages/quickfiling";
import About from "@/pages/about";
import QuickFilingDashboard from "@/pages/quickfiling/dashboard";
import QuickFilingAccount from "@/pages/quickfiling/account";
import CommunityLayout from "@/pages/community";
import ProfileStepForm from "@/components/quickfilingComp/dashboardProfile/profileForm";
import OrganizeResearch from "@/pages/quickfiling/organizeResearch";
import CheckMissFile from "@/pages/quickfiling/checkMissFile";
import MainSteps from "@/components/quickfilingComp/mainSteps";
import ReviewCitation from "@/pages/quickfiling/reviewCitation";

const router = createBrowserRouter([
    {
        path: "/test",
        element: <Test/>
    },
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                index: true,
                element: <HomePage/>,
            },
            {
                path: '/chat',
                element: <ChatApp/>,
            },
            {
                path: '/quickfiling',
                element: <QuickFiling/>,
                children: [
                    {
                        index: true,
                        element: <QuickFilingDashboard/>,
                    },
                    {
                        index: true,
                        path: '/quickfiling/account',
                        element: <QuickFilingAccount/>,
                    },
                    {
                        path: '/quickfiling/process',
                        element: <MainSteps />,
                        children : [
                            {
                                path: '/quickfiling/process/profile',
                                element: <ProfileStepForm/>,
                            },
                            {
                                path: '/quickfiling/process/category',
                                element: <OrganizeResearch/>,
                            },
                            {
                                path: '/quickfiling/process/checkMissing',
                                element: <CheckMissFile/>,
                            },
                            {
                                path: '/quickfiling/process/review',
                                element: <ReviewCitation/>,
                            },
                        ]
                    }
                ]
            },
            {
                path: '/track',
                element: <Tracker/>,
            },
            {
                path: '/community',
                element: <CommunityLayout/>,
                children: [
                    {
                        index: true,
                        element: <Community/>,
                    },
                    {
                        path: "/community/create",
                        element: <Create/>,
                    },
                ]
            },

            {
                path: '/specialist',
                element: <Specialist/>,
            },
            {
                path: '/about',
                element: <About/>,
            },
            /*用户界面排期靠后*/
            {
                path: "/user",
                element: <Personal/>,
                children: [
                    {
                        path: "/user/info",
                        element: <UserInfo/>,
                    },
                    {
                        index: true,
                        element: <History/>,
                    },
                    {
                        path: "/user/saved",
                        element: <Saved/>,
                    },
                    {
                        path: "/user/password",
                        element: <Password/>,
                    }
                ]
            },

        ]
    },
    {
        path: "/email/verification",
        element: <EmailVerifyPage/>,
    },
    {
        path: "*", // 捕获所有未匹配的路径
        element: <NotFoundPage/>,
    }

])


export default router




