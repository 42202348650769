import {Button, Col, Flex, Row, Space,  Typography} from "antd";
import { CheckCircleFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";



const {Title} = Typography;

const NIWKit = () => {


    return (
        <div className="plan-item">
            <div className={'plan-item-title'}>
                <h2>NIWKit</h2>
            </div>
            <div className={'plan-item-link'}>
                <div>
                    <Link to={'#'}>Get Started - NIWKit</Link>
                </div>
                <br/>
                <div>
                    <Link to={'#'}>How to edit your petition package?</Link>
                </div>
            </div>
            <div className="plan-item-content">
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div className={'plan-item-feature'}>
                            <Title level={4}>Features</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Everything included in SoCKit</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Generate entire petition package (instructions, cover
                                letter, I-140, etc.)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Approved NIW petition letter examples</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Generate NIW Petition Letter (5 times, $60/Gen after
                                that, max $180)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Assemble all docs into one ready-to-print-and-file PDF
                                (3 times, $60/Gen after that, max $180)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Document Translation by AI ($1 per page, $50 minimum)</Title>
                        </div>
                    </Col>

                    <Col span={7}>
                        <Title level={4}>Pricing</Title>
                        <br/>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>NIWKit</Title></div>
                            <div><Title level={5}>$749 / Month</Title></div>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>Add on - 1807 citations</Title></div>
                            <div><Title level={5}>$300 / Month</Title></div>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>Subtotal</Title></div>
                            <div><Title level={5}>$1049 / Month</Title></div>
                        </Flex>
                    </Col>
                    <Col span={9}>
                        <Flex className={'imigraBoost-content-button'} justify={'center'} align={'center'}>
                            <div>
                                <Space direction={'vertical'}>
                                    <Button style={{width: '100%'}} type={"primary"}>Pay</Button>
                                    <Button style={{width: '100%'}} type={"primary"}>Demo</Button>
                                    <Button style={{width: '100%'}} type={"primary"}>view highlights</Button>
                                    <Link to={'#'}>How to Gen Demo</Link>
                                </Space>
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default NIWKit