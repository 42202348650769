import UploadFileBox from "@/components/common/dragUpload";
import {Col, Divider, Form, Input, Row, Select, Typography} from "antd";
import React from "react";

const {Title} = Typography;

const ProfileInfoStepForm = () => {


    return (
        <div>
            <div>
                <Title level={3}>Intro</Title>
            </div>
            <Divider/>
            <div style={{marginBottom: 30}}>
                <UploadFileBox/>
            </div>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item label="Title" name='title'>
                        <Select>
                            <Select.Option value="0">Mr</Select.Option>
                            <Select.Option value="1">Mrs</Select.Option>
                            <Select.Option value="2">Miss</Select.Option>
                            <Select.Option value="3">Dr</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="firstname"
                        label="First Name"
                        rules={[{required: true, message: '请输入姓名'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="middleName"
                        label="Middle Name"
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="lastname"
                        label="Last Name"
                        rules={[{required: true, message: '请输入姓名'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="suffix"
                        label="Suffix"
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Gender" name='gender'>
                        <Select>
                            <Select.Option value="0">Male</Select.Option>
                            <Select.Option value="1">Female</Select.Option>
                            <Select.Option value="2">Other</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )

}

export default ProfileInfoStepForm;