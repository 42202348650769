import React, {useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {Button, Flex, Typography, Table, Tag} from "antd";



const {Paragraph} = Typography;


const data = [
    {
        key: '1',
        title: 'High loading of single atoading of single atoading of single atoading of single atoading of single atoading of single atomic iron sites',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        judge: 'Positive',
        highlights: 'This was suoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atccessfully demonstrated in a proof-of-concept',
    },
    {
        key: '2',
        title: 'High loading of single atomic iron sites',
        publication: 'Nature Catalysis, 2022 - nature.com',
        judge: 'Positive',
        highlights: 'In the optimised Fe-NC caading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic irotalys',
    },
    {
        key: '3',
        title: 'Insights into the activity of single-atom Fe–NC',
        publication: 'Nature, 2022 - nature.com',
        judge: 'Neutral',
        highlights: 'Insights iading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iro–NC catalyst',
    },
    {
        key: '4',
        title: 'Insights into the activity of single-atom Fe–NC',
        publication: 'Nature, 2022 - nature.com',
        judge: 'Positive',
        highlights: 'Further pading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroerformance characterisation in fuel cells',
    },
];


// TextMore component from your example
const TextMore = ({ text }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            <Paragraph
                ellipsis={visible ? false : { rows: 3, expandable: true, symbol: <Button size={"small"} type={"text"} style={{ visibility: 'hidden' }}>More</Button> }}
            >
                {text}
                {visible && <Button danger type={"text"} size={"small"}  onClick={() => setVisible(false)} >Less</Button>}
            </Paragraph>
            {!visible && (
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <Button danger type={"text"} size={"small"} onClick={() => setVisible(true)}>More</Button>
                </div>
            )}
        </div>
    )
};



const ReviewHighlightsTable = () => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };


    useEffect(()=>{
        setDataSource(data)
    }, [])


    const columns = [

        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '200px',
            className: 'word-break',
        },
        {
            title: 'Publication',
            dataIndex: 'publication',
            key: 'publication',
            width: '150px',
            className: 'word-break',
        },
        {
            title: 'Judge',
            dataIndex: 'judge',
            key: 'judge',
            render: (_, record) => {
                return (
                    <Tag color={'#87d068'}>{record.judge}</Tag>
                )
            },
            width: '40px'
        },

        {
            title: 'Highlights',
            dataIndex: 'highlights',
            key: 'highlights',
            render: (_, record) => {
                return (
                    <TextMore text={record.highlights} />
                )
            },
            width: '300px'
        },

    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div>
            <Title level={3}>Review your highlights</Title>

            <Flex justify={"center"} align={"center"}
                  style={{width: 600, padding: '0 5px', height: 45, backgroundColor: '#e6e4e4', borderRadius: 10}}>
                <Button>
                    All Citations
                </Button>
                <Button>
                    Positive
                </Button>
                <Button>
                    Neutral
                </Button>
                <Button>
                    Negative
                </Button>
                <Button>
                    SelfCitation
                </Button>
            </Flex>


            <Table rowSelection={rowSelection}
                   columns={columns}
                   pagination={false}
                   dataSource={dataSource}
            />
        </div>
    )
}


export default ReviewHighlightsTable