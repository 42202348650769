

import { createSlice } from '@reduxjs/toolkit'
import {localStorageGetQuickFilingForm, localStorageRemoveQuickFilingForm} from "@/utils/token";


const quickStore = createSlice({
    name: "quickForm",
    // 数据状态
    initialState: {
        quickFilingForm: localStorageGetQuickFilingForm(),
    },
    // 同步修改方法
    reducers: {

        setQuickForm (state, action) {
            state.quickFilingForm = action.payload
        },
        clearQuickForm (state) {
            state.quickFilingForm = {}
            localStorageRemoveQuickFilingForm()
        }
    }
})


const { setQuickForm, clearQuickForm } = quickStore.actions

const quickFormReducer = quickStore.reducer

export { setQuickForm, clearQuickForm }

export default quickFormReducer