import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Steps} from 'antd';
import './index.scss';
import ProfileInfoStepForm from "@/components/quickfilingComp/dashboardProfile/personalInfo";
import EduEmployStepForm from "@/components/quickfilingComp/dashboardProfile/eduEmployForm";
import ResearchAwards from "@/components/quickfilingComp/dashboardProfile/researchAwards";
import ExternalRecAdoption from "@/components/quickfilingComp/dashboardProfile/external";
import moment from 'moment';
import {
    localStorageSetQuickFilingForm,
    localStorageGetQuickFilingForm,
} from "@/utils/token";
import {useNavigate} from "react-router-dom";
import {CurrentStepContext} from "@/components/quickfilingComp/mainSteps";

const {Step} = Steps;



export const removeCurrentFormItem = (form, itemName, index) => {
    // 获取当前表单数据
    const values = form.getFieldsValue();
    const savedData = JSON.parse(localStorage.getItem('quickfiling-formData')) || {};
    const stepKey = itemName;  // 假设当前步骤的 key

    // 移除 values 中 unlistedArticles 对应的项
    const updatedArticles = values[stepKey].filter((_, i) => i !== index);

    // 更新 localStorage
    localStorage.setItem('quickfiling-formData', JSON.stringify({
        ...savedData,
        [stepKey]: updatedArticles
    }));
}

export const moveItem = (form, index, direction, fields, tableName) => {
    if ((index === 0 && direction === -1) || (index === fields.length - 1 && direction === 1)) {
        return; // Prevent moving out of bounds
    }
    // 获取当前的表单值
    const currentValues = form.getFieldValue(tableName);
    // 交换当前索引与目标索引的值
    const temp = currentValues[index];
    currentValues[index] = currentValues[index + direction];
    currentValues[index + direction] = temp;
    // 重新设置表单值
    form.setFieldsValue({
        tableName: currentValues,
    });
};

const getSteps = (form, tabNum, setTabNum) => {

    return [
        {
            title: 'Personal Information',
            key: 'personalInformation',
            content: (
                <ProfileInfoStepForm/>
            ),
        },
        {
            title: 'Education and Employment History',
            key: 'eduEmployHistory',
            content:
                <EduEmployStepForm form={form}/>
        },
        {
            title: 'Research & Awards',
            key: 'researchAndAwards',
            content: <ResearchAwards form={form}/>
        },
        {
            title: 'External Recognition & Adoption',
            key: 'externalRecognition',
            content: <ExternalRecAdoption form={form} tabNum={tabNum} setTabNum={setTabNum}/>
        },
    ];

}


const getQuickFilingDataFromLocalStorage = () => {

    const savedData = JSON.parse(localStorageGetQuickFilingForm()) || {};
    // 将需要转换的日期字段逐一进行处理
    if (savedData) {
        if (savedData.education) {
            savedData.education.forEach(item => {
                if (item.graduationYear) {
                    item.graduationYear = moment(item.graduationYear); // 转换为 moment 对象
                }
            });
        }
        if (savedData.fundingAward) {
            savedData.fundingAward.forEach(item => {
                if (item.awardDate) {
                    item.awardDate = moment(item.awardDate);
                }
            });
        }

        if (savedData.honors) {
            savedData.honors.forEach(item => {
                if (item.receiveDate) {
                    item.receiveDate = moment(item.receiveDate);
                }
            });
        }

        if (savedData.presentations) {
            savedData.presentations.forEach(item => {
                if (item.receiveDate) {
                    item.receiveDate = moment(item.receiveDate);
                }
            });
        }

        if (savedData.professionalMembership) {
            savedData.professionalMembership.forEach(item => {
                if (item.dateOfJoining) {
                    item.dateOfJoining = moment(item.dateOfJoining);
                }
            });
        }

        if (savedData.awardFundingReviewerExperience) {
            savedData.awardFundingReviewerExperience.forEach(item => {
                if (item.year) {
                    item.year = moment(item.year);
                }
            });
        }
    }

    return savedData;
}


const ProfileStepForm = () => {

    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const [tabNum, setTabNum] = useState(1);
    const {setMainCurrent} = useContext(CurrentStepContext)

    const steps = getSteps(form, tabNum, setTabNum);
    const navigate = useNavigate()

    // 当页面加载时，从 localStorage 中加载表单数据
    useEffect(() => {
        const savedData = getQuickFilingDataFromLocalStorage()
        if (savedData) {
            form.setFieldsValue(savedData);
        }
        setMainCurrent(0)
    }, [current, form]);


    // const tabsName = ['professionalExperiences', 'industryAdoption', 'educationAdoption']
    // const getKeyForSaveData = () => {
    //
    //     if (current === 3 && tabNum < 3) {
    //             return tabsName[tabNum];
    //     }else{
    //         return steps[current].key;
    //     }
    // }


    // 保存当前步骤的数据到 localStorage
    const saveCurrentStepData = () => {
        try {
            const values = form.getFieldsValue();
            const savedData = JSON.parse(localStorageGetQuickFilingForm()) || {};
            for (const key in values) {
                if (Array.isArray(values[key])) {
                    // For Form.List arrays, filter out empty entries
                    // Only save the non-empty list
                    const filteredList = values[key].filter(item => item &&
                        typeof item === 'object' &&
                        Object.values(item).some(value => value !== null && value !== undefined && value !== '')
                    );
                    // Only save the non-empty list
                    if (filteredList.length > 0) {
                        savedData[key] = filteredList;
                    }
                } else {
                    // Check if the non-list field has a value
                    if (values[key] !== null && values[key] !== undefined && values[key] !== '') {
                        savedData[key] = values[key];
                    }
                }
            }
            localStorageSetQuickFilingForm(JSON.stringify(savedData));
            return true
        } catch (error) {
            console.log('Validation Failed:', error);
            return false;
        }
    };

    const next = async () => {
        try {
            await form.validateFields();
            // 保存当前步骤的数据
            if (!saveCurrentStepData()) {
                return
            }
            if (current === steps.length - 1) {
                setTabNum(tabNum + 1);
            } else {
                setCurrent(current + 1);
            }
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };

    const clickStep = async (index) => {
        try {
            await form.validateFields();
            setCurrent(index)
        } catch (error) {
            console.log('Validation Failed:', error);
        }

    }

    const prev = () => {
        if (current === steps.length - 1 && tabNum > 1) {
            setTabNum(tabNum - 1);
        } else {
            setCurrent(current - 1);
        }
    };


    const handleSubmit = async () => {
        const isValid = saveCurrentStepData();
        if (!isValid) return;
        const savedData = JSON.parse(localStorageGetQuickFilingForm()) || {};
        console.log('Final data:', savedData); // 可以在此查看所有步骤的数据
        // dispatch(setQuickForm(savedData));
        navigate('/quickfiling');

    };

    return (
        <div className="step-form-container">
            <div className="progress-bar">
                <Steps direction="vertical" current={current}>
                    {steps.map((item, index) => (
                        <Step
                            key={index}
                            title={item.title}
                            onClick={() => clickStep(index)}
                            className={current >= index ? 'step-completed' : 'step-pending'}
                        />
                    ))}
                </Steps>
            </div>
            <div className="form-content">
                <Form form={form} layout="vertical">
                    <div>{steps[current].content}</div>
                    <div className="form-navigation">
                        {current > 0 && (
                            <Button style={{margin: '0 8px'}} onClick={prev}>
                                Prev
                            </Button>
                        )}
                        {current <= steps.length - 1 && tabNum !== 3 && (
                            <Button type="primary" onClick={next}>
                                Next
                            </Button>
                        )}
                        {current === steps.length - 1 && tabNum === 3 && (
                            <Button type="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ProfileStepForm;
