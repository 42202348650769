import React, {useState, createContext} from 'react';
import { Steps} from 'antd';
import './index.scss';
import {Outlet, useNavigate} from "react-router-dom";


const {Step} = Steps;


const steps = [
    {
        title: 'Create Profile',
        style: '',
        link: '/quickfiling/process/profile'
    },
    {
        title: 'Organize Research',
        style: '',
        link: '/quickfiling/process/category'
    },
    {
        title: 'Check Missing Files',
        style: '',
        link: '/quickfiling/process/checkMissing'
    },
    {
        title: 'Review Citations',
        style: '',
        link: '/quickfiling/process/review'
    },
    {
        title: 'Proposed Endeavor',
        style: '',
        link: '#'
    },
    {
        title: 'Translate Documents',
        style: '',
        link: '#'
    },
    {
        title: 'Generate Report',
        style: '',
        link: '#'
    },
]

// 创建 Context
export const CurrentStepContext = createContext();

const MainSteps = () => {

    const [mainCurrent, setMainCurrent] = useState(0)
    const navigate = useNavigate();
    // 模拟跳转逻辑
    const handleStepClick = (link) => {
        navigate(link)
    };


    return (


        <div style={{margin: '20px'}}>
            <div className={'main-steps'}>
                <Steps current={mainCurrent}>
                    {steps.map((step, index) => (
                        <Step
                            key={index}
                            title={step.title}
                            className={`step ${index < mainCurrent ? 'completed' : ''} ${index === 0 ? 'first-step' : ''} ${
                                index === steps.length - 1 ? 'last-step' : ''
                            }`}
                            onClick={index < mainCurrent ? () => handleStepClick(step.link) : undefined} /* 点击已完成步骤 */
                            style={{cursor: index <= mainCurrent ? 'pointer' : 'not-allowed'}} /* 修改鼠标样式，未完成的不可点击 */
                        />
                    ))}
                </Steps>
            </div>
            <CurrentStepContext.Provider value={{mainCurrent, setMainCurrent}}>
                <Outlet/>
            </CurrentStepContext.Provider>
        </div>


    )
        ;
};

export default MainSteps;
