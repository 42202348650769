import React from 'react';
import {Form, Input, Button, Space, Select, DatePicker, Row, Col, Divider, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import "./index.scss"
import UploadFileBox from "@/components/common/dragUpload";
import {moveItem, removeCurrentFormItem} from "@/components/quickfilingComp/dashboardProfile/profileForm";
const {Option} = Select;

const {Title} = Typography;

const EduEmployStepForm = ({form}) => {


    let addEducation;  // 定义一个变量保存 `add` 方法
    let addEmployment;  // 定义一个变量保存 `add` 方法



    return (
        <div className={'eduEmployForm'}>
            <div >
                <div>
                    <Title level={3}>Education & Employment</Title>
                </div>
                <Divider/>
                <Title level={4}>Education History</Title>
                <p>Add your highest degree only</p>
                <br/>
                <Form.List name="education">
                    {(fields, {add, remove}) => {
                        addEducation = add;
                        const customRemove = ( name, index) => {
                            remove(name);
                            removeCurrentFormItem(form, "education", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => (
                                    <div className={'edu-history'} key={index}>
                                        <Title level={5}>*education-{index + 1}</Title>
                                        <Space align={'start'}>
                                            <div>
                                                <div className={'edu-history-item'}>
                                                    <Row gutter={16}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'degree']}
                                                                // rules={[{required: true, message: 'please input degree'}]}
                                                                label='Degree'
                                                            >
                                                                <Select >
                                                                    <Option value="bachelor">Bachelor</Option>
                                                                    <Option value="master">Master</Option>
                                                                    <Option value="phd">PhD</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'major']}
                                                                // rules={[{required: true, message: 'please input major'}]}
                                                                label='Major'
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'university']}
                                                                // rules={[{required: true, message: 'please input university'}]}
                                                                label='University'
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'graduationYear']}
                                                                // rules={[{required: true, message: 'please input graduationYear'}]}
                                                                label='Graduation Year'
                                                            >
                                                                <DatePicker picker="year" format="YYYY" showTime={false}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Title level={5}>Diploma File</Title>
                                                <UploadFileBox/>
                                            </div>
                                            <Space direction={"vertical"}>
                                                {fields.length > 1 && <Space direction={"vertical"}>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, -1, fields, 'education')}>Up</Button>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, 1, fields, 'education')}>Down</Button>
                                                </Space>}
                                                <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                            </Space>
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addEducation()}
                            icon={<PlusOutlined/>}
                        >
                            Add Education
                        </Button>
                    </Form.Item>
                </div>
            </div>

            {/*雇佣信息*/}
            <div>
                <div>
                    <Title level={4}>Employment History</Title>
                </div>
                <Divider/>
                <Form.List name="employment">
                    {(fields, {add, remove}) => {
                        addEmployment = add;
                        const customRemove = ( name, index) => {
                            remove(name);
                            removeCurrentFormItem(form, "employment", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => (
                                    <div className={'edu-history'} key={index}>
                                        <Title level={5}>*employment-{index + 1}</Title>
                                        <Space align={'start'}>
                                            <div>
                                                <div className={'edu-history-item'}>
                                                    <Row gutter={16}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'employer']}
                                                                label='Employment Name'
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'position']}
                                                                label='Position Title'
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'jobDuty']}
                                                                label='Job Duty'
                                                            >
                                                                <Input.TextArea rows={6}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Title level={5}>Employment Letter</Title>
                                                <UploadFileBox/>
                                            </div>
                                            <Space direction={"vertical"}>
                                                {fields.length > 1 && <Space direction={"vertical"}>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, -1, fields, 'employment')}>Up</Button>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, 1, fields, 'employment')}>Down</Button>
                                                </Space>}
                                                <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                            </Space>
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addEmployment()}
                            icon={<PlusOutlined/>}
                        >
                            Add Employment
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </div>

    );
};

export default EduEmployStepForm;
