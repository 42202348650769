// 组合redux子模块 + 导出store实例

import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/user'
import quickFormReducer from "@/store/modules/quick";

export default configureStore({
    reducer: {
        user: userReducer,
        quickForm: quickFormReducer
    },
    devTools: true,
})