import React, {useState} from 'react';
import {CustomerServiceOutlined, HomeOutlined, UserOutlined} from '@ant-design/icons';
import {Flex, FloatButton, Layout, Menu} from 'antd';
import {Outlet} from "react-router-dom";
const {Content, Sider } = Layout;



const items = [
    {
        key: 1,
        label: <Flex justify={'flex-start'} style={{fontSize: 20}}><HomeOutlined style={{fontSize: 20}}/><span>Dashboard</span></Flex>,

    },
    {
        key: 2,
        label: <Flex justify={'flex-start'} style={{fontSize: 20}}><UserOutlined style={{fontSize: 20}}/><span>Account</span></Flex>,

    },

];
const QuickFiling = () => {

    const [current, setCurrent] = useState(1);
    const onClick = (e) => {
        setCurrent(e.key);
    };


    const onClickFloatButton = () => {

    }

    return (
        <Layout
            style={{
                minHeight: '93vh',
            }}
        >
            <Sider theme="light" >
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="inline"
                    items={items}
                />

            </Sider>
            <Layout >
                <Content>
                    <Outlet/>
                    <FloatButton
                        onClick={onClickFloatButton}
                        icon={<CustomerServiceOutlined  style={{ fontSize: '20px' }} />}
                        type="primary"
                        shape={"square"}
                        style={{
                            insetInlineEnd: 20,
                            width: 45,  // 调整宽度
                            height: 45, // 调整高度
                        }}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};
export default QuickFiling;

