import React, {useState, useEffect, useRef} from 'react';
import {Card, List, Input, Button, Avatar, Spin, Typography} from 'antd';
import {askChatGPTApi} from '@/apis/chat';

import {isEmptyArray, isEmptyObj} from '@/utils';


const {TextArea} = Input;
const {Title} = Typography;

const ChatBox = () => {
    const [messages, setMessages] = useState([{
        content: `welcome...`
    }]);
    const [isLoading, setIsLoading] = useState(false); // 用于跟踪是否正在加载

    const messagesEndRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const renderMessageContent = (content) => {
        return content.split('\n').map((line, index) => (<span key={index}>
                {line}
            <br/>
            </span>));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // 阻止默认的换行行为
            handleSend(); // 调用发送消息函数
        }
    };

    const handleSend = () => {
        if (inputValue.trim()) {
            setMessages([...messages, {sender: 'user', content: inputValue}]);
            setIsLoading(true);  // 发送消息后设置加载状态为true
            setInputValue('');
            const data = {
                content: inputValue,
            }
            askChatGPTApi(data).then(res => {
                if (res.code !== 0 && isEmptyArray(res.data.choices)) {
                    throw new Error("choices is empty");
                }
                let respMessage = res.data.choices[0].message;
                if (isEmptyObj(respMessage)) {
                    respMessage = {
                        "role": "assistant", "content": "sorry, I can not understand you, please input again",
                    }
                }
                setMessages((prevMessages) => [...prevMessages, respMessage,]);
                setIsLoading(false);  // 回复收到后取消加载状态
            }).catch(err => {
                console.log("gpt err: ", err)
            })
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className={'flexCenter'}>
            <Card style={{height: '580px', width: 600, marginTop: 10}}>
                <div style={{height: '400px', padding: '10px', overflowY: 'auto'}}>
                    <List
                        itemLayout="horizontal"
                        dataSource={messages}
                        split={false}
                        header={<Title level={4}>Chat with AI</Title>}
                        footer={isLoading ? <div className='flexCenter'><Spin size='small'/></div> : null}
                        renderItem={(item) => (<List.Item
                            style={{
                                display: 'flex',
                                justifyContent: item.sender === 'user' ? 'flex-end' : 'flex-start',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    maxWidth: '80%',
                                    flexDirection: item.sender === 'user' ? 'row-reverse' : 'row',
                                    alignItems: 'flex-start',  // 确保头像和消息框都顶部对齐
                                }}
                            >
                                <Avatar
                                    style={{
                                        backgroundColor: item.sender === 'user' ? '#87d068' : '#1890ff',
                                        width: '40px', // 固定宽度
                                        height: '40px', // 固定高度
                                        marginLeft: item.sender === 'user' ? '10px' : '0',
                                        marginRight: item.sender === 'user' ? '0' : '10px',
                                    }}
                                >
                                    {item.sender === 'user' ? 'U' : 'B'}
                                </Avatar>
                                <div
                                    style={{
                                        maxWidth: '80%',
                                        backgroundColor: item.sender === 'user' ? '#f5f5f5' : '#e6f7ff',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        wordWrap: 'break-word'
                                    }}
                                >
                                    {renderMessageContent(item.content)}
                                </div>
                            </div>
                        </List.Item>)}
                    />
                    <div ref={messagesEndRef}/>
                </div>
                <div style={{padding: '10px', borderTop: '1px solid #f0f0f0'}}>
                    <TextArea
                        rows={4}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Type your message..."
                        style={{
                            width: '100%', height: '80%',    // 高度设为父标签高度的80%
                            resize: 'none'    // 禁止手动拖拽调整大小
                        }}
                        variant={'borderless'}
                        onKeyDown={handleKeyDown} // 监听键盘事件
                    >
                    </TextArea>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type="primary" onClick={handleSend}>
                            Send
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ChatBox;
