import React from 'react';
import {Form, Input, Button, Space, DatePicker, Row, Col, Divider, Tabs, Checkbox, Tooltip, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import "./index.scss"

import UploadFileBox from "@/components/common/dragUpload";
import {moveItem, removeCurrentFormItem} from "@/components/quickfilingComp/dashboardProfile/profileForm";

const {Title} = Typography;

const ProfessionalExperiences = ({form}) => {

    let addReviewerExperience;
    let addProfessionalMembership;
    let addAwardFundingReviewerExperience;
    let addConsultingExperience;

    return (
        <div className={'eduEmployForm'}>
            <Title level={4}>Reviewer Experience</Title>
            <Divider/>
            <Form.List name="reviewerExperience">
                {(fields, {add, remove}) => {
                    addReviewerExperience = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "reviewerExperience", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*reviewer experience-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'journalName']}
                                                            label='Conference/Journal Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'year']}
                                                            label='Year'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'role']}
                                                            label={<Tooltip title={'PC member, editor, reviewer, etc.'}>Your Role</Tooltip>}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'ranking']}
                                                            label='Ranking of the above Conference/Journal'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof of Ranking</Title>
                                            <UploadFileBox/>
                                            <br/>
                                            {/*todo*/}
                                            <Title level={5}>Proof of Reviewer Experience</Title>

                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'reviewerExperience')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'reviewerExperience')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addReviewerExperience()}
                        icon={<PlusOutlined/>}
                    >
                        Add Reviewer Experience
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Professional Membership</Title>
            <Divider/>
            <Form.List name="professionalMembership">
                {(fields, {add, remove}) => {
                    addProfessionalMembership = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "professionalMembership", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*professional membership-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'organizationName']}
                                                            label='Name of Organization'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'website']}
                                                            label='Official Website'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'intro']}
                                                            label='Organization Intro'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'membershipType']}
                                                            label='Membership Type'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'dateOfJoining']}
                                                            label='Date Of Joining'
                                                        >
                                                            <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'professionalMembership')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'professionalMembership')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addProfessionalMembership()}
                        icon={<PlusOutlined/>}
                    >
                        Add Professional Membership
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Award & Funding Reviewer Experience</Title>
            <Divider/>
            <Form.List name="awardFundingReviewerExperience">
                {(fields, {add, remove}) => {
                    addAwardFundingReviewerExperience = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "awardFundingReviewerExperience", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*award & funding reviewer-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'awardFundingName']}
                                                            label='Award Funding Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'aboutAwardFunding']}
                                                            label={<Tooltip title={'What is this award about, is it competitive? or does the award have big impact in your field?'}>About Award Funding</Tooltip>}
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'year']}
                                                            label='Year'
                                                        >
                                                            <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'agencyOrganization']}
                                                            label={<Tooltip title={'Who created this funding or awards, such as NSF.'}>Agency Organization</Tooltip>}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'agencyOrganizationIntro']}
                                                            label='About Agency Organization'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Award Funding Proof</Title>
                                            <UploadFileBox/>
                                            <br/>
                                            <Title level={5}>Agency Organization Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'awardFundingReviewerExperience')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'awardFundingReviewerExperience')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addAwardFundingReviewerExperience()}
                        icon={<PlusOutlined/>}
                    >
                        Add Award & Funding Reviewer Experience
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Consulting Experience</Title>
            <Divider/>
            <Form.List name="consultingExperience">
                {(fields, {add, remove}) => {
                    addConsultingExperience = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "consultingExperience", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*consulting experience-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'consulteeName']}
                                                            label={<Tooltip title={'This refers to the person or organization that receives advice or services from a consultant'}>Consultee Name</Tooltip>}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'aboutConsultee']}
                                                            label={<Tooltip title={'Short intro about the consultee, focuses on its impact on your fields, e.g., #1 xx provider in xx area'}>About Consultee</Tooltip>}
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'summaryOfConsulting']}
                                                            label='Summary Of Consulting Service'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'expertiseNeeded']}
                                                            label='Expertise Needed'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Consulting Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'consultingExperience')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'consultingExperience')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addConsultingExperience()}
                        icon={<PlusOutlined/>}
                    >
                        Add Consulting Experience
                    </Button>
                </Form.Item>
            </div>


        </div>
    )
}


const IndustryAdoption = ({form}) => {

    let addOpenSourceProjects;
    let addWorkUsedByIndustry;
    let addRequestsForDataCodeOrPapers;

    return (
        <div className={'eduEmployForm'}>
            <Title level={4}>Open Source Projects</Title>
            <Divider/>
            <Form.List name="openSourceProjects">
                {(fields, {add, remove}) => {
                    addOpenSourceProjects = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "openSourceProjects", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*open source projects-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'name']}
                                                            label='Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'intro']}
                                                            label='Brief Intro'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'github']}
                                                            label='Github Link'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'usedByOther']}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox>Used By Other Projects</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'openSourceProjects')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'openSourceProjects')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addOpenSourceProjects()}
                        icon={<PlusOutlined/>}
                    >
                        Add Open Source Projects
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Work Used By Industry</Title>
            <Divider/>
            <Form.List name="workUsedByIndustry">
                {(fields, {add, remove}) => {
                    addWorkUsedByIndustry = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "workUsedByIndustry", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*work used by industry-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'companyName']}
                                                            label='Company Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'useCase']}
                                                            label='Use Case'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'impact']}
                                                            label='Impact'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'workUsedByIndustry')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'workUsedByIndustry')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addWorkUsedByIndustry()}
                        icon={<PlusOutlined/>}
                    >
                        Add Work Used By Industry
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Requests For Data, Code or Papers</Title>
            <p>Did you receive emails from others that asks from your code,
                data or papers? or you were invited to work for a company
                because of your research?
            </p>
            <Divider/>
            <Form.List name="requestsForDataCodeOrPapers">
                {(fields, {add, remove}) => {
                    addRequestsForDataCodeOrPapers = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "requestsForDataCodeOrPapers", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*requests for-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'requesterName']}
                                                            label='Requester Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'aboutRequester']}
                                                            label='About Requester'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'summaryOfRequest']}
                                                            label={<Tooltip title={'Short summary of what the request asks for,and why your work is important to them'}>Summary Of Request</Tooltip>}
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'relatedResearch']}
                                                            label='Related Research'
                                                        >
                                                            <Input.TextArea rows={2}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Request Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'workUsedByIndustry')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'workUsedByIndustry')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addRequestsForDataCodeOrPapers()}
                        icon={<PlusOutlined/>}
                    >
                        Add Requests For Data, Code or Papers
                    </Button>
                </Form.Item>
            </div>
        </div>
    )
}

const EducationAdoption = ({form}) => {
    let addWorkUsedForCourses;
    return (
        <div className={'eduEmployForm'}>
            <Title level={4}>Work Used For Courses</Title>
            <Divider/>
            <Form.List name="workUsedForCourses">
                {(fields, {add, remove}) => {
                    addWorkUsedForCourses = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "workUsedForCourses", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*work used for courses-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'courseName']}
                                                            label='Course Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'university']}
                                                            label='University'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'usageDescription']}
                                                            label={<Tooltip title={'How your work is used in the project'}>Usage Description</Tooltip>}
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'workUsedForCourses')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'workUsedForCourses')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addWorkUsedForCourses()}
                        icon={<PlusOutlined/>}
                    >
                        Add Work Used For Courses
                    </Button>
                </Form.Item>
            </div>
        </div>
    )
}


const getItems = (form) => {

    return [
        {
            key: 1,
            label: "Professional Experiences",
            children: <ProfessionalExperiences form={form}/>
        },
        {
            key: 2,
            label: "Industry Adoption",
            children: <IndustryAdoption form={form}/>
        },
        {
            key: 3,
            label: "Education Adoption",
            children: <EducationAdoption form={form}/>
        },

    ]
}


const ExternalRecAdoption = ({form, tabNum, setTabNum}) => {

    const onChange = (key) => {
        setTabNum(key)
    }


    const Items = getItems(form)
    return (
        <div className={'external'}>
            <Tabs
                defaultActiveKey={1}
                activeKey={tabNum}
                items={Items}
                size={"large"}
                indicator={{size: 150, align: "start"}}
                onChange={onChange}
            />
        </div>
    );
};

export default ExternalRecAdoption;
