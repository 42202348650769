
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Space,
    Tooltip,
    Typography
} from "antd";
import {Field} from "@/data/data";
import React, {useState} from "react";
import UploadFileBox from "@/components/common/dragUpload";
import {PlusOutlined} from "@ant-design/icons";
import {moveItem} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import "./index.scss"
import {removeCurrentFormItem} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import ScholarPapersTable from "@/components/quickfilingComp/tables/scholarPapersTable";


const {Option} = Select;
const {Title } = Typography;

const ResearchAwards = ({form}) => {

    const [open, setOpen] = useState(false);
    const [activeFormListIndex, setActiveFormListIndex] = useState(null);  // 当前激活的 form list
    const [activeFormType, setActiveFormType] = useState(null);  // 区分哪个 Form List 触发 Drawer
    const [inputFieldName, setInputFieldName] = useState(null);  // 用于区分是哪个 input 被点击

    const showDrawer = (formListIndex, formType, inputField) => {
        setActiveFormListIndex(formListIndex);  // 记录当前点击的 form list
        setActiveFormType(formType);  // 记录点击的是哪个 form list
        setInputFieldName(inputField);  // 记录点击的是哪个 input
        setOpen(true);  // 打开 Drawer
    };

    const closeDrawer = () => {
        setOpen(false);
        setActiveFormListIndex(null);  // 重置 activeFormListIndex
        setActiveFormType(null);  // 重置 formType
        setInputFieldName(null);  // 重置 inputField
    };


    const handleSelect = (selectedKey) => {
        // 将选中的条目填充到表单的对应 Input 框中，使用 inputFieldName 确定是哪一个 input 框
        form.setFieldValue([activeFormType, activeFormListIndex, inputFieldName], selectedKey);
        closeDrawer();  // 关闭 Drawer
    };


    let addUnlistedArticles;
    let addMissingCitation;
    let addHighlightComments;
    let addBooks;
    let addPatent;
    let addHonors;
    let addFundingAward;
    let addMediaReports;
    let addPresentations;



    return (
        <div className={'eduEmployForm'}>
            <div>
                <Title level={3}>Research & Awards</Title>
            </div>
            <Divider/>
            <Form.Item name="research" label='Research Field'>
                <Select>
                    {Field.map((item, index) => (
                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item name='interest' label='Research Interest'>
                <Input.TextArea rows={5}></Input.TextArea>
            </Form.Item>

            <Form.Item name='scholarURL' label='Google Scholar Url'>
                <Input/>
            </Form.Item>
            <Title level={4}>Unlisted Articles</Title>
            <p>Articles Not Indexed on Google Scholar</p>
            <Divider/>
            <Form.List name="unlistedArticles">
                {(fields, {add, remove}) => {
                    addUnlistedArticles = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "unlistedArticles", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*UnlistedArticles-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row >
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'title']}
                                                            label='Title'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'journal']}
                                                            label='Journal Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'status']}
                                                            label='status'
                                                        >
                                                            <Select >
                                                                <Option value="0">accepted</Option>
                                                                <Option value="1">under review</Option>
                                                                <Option value="2">published</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                            <br/>
                                            <Title level={5}>Article Draft</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'unlistedArticles')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'unlistedArticles')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}

                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addUnlistedArticles()}
                        icon={<PlusOutlined />}
                    >
                        Add Unlisted Articles
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Upload Missing Citation</Title>
            <Divider/>
            <Form.List name="missingCitation">
                {(fields, {add, remove}) => {
                    addMissingCitation = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "missingCitation", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Missing Citation-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'citedArticle']}
                                                            label='Cited Article'
                                                        >
                                                            <Input readOnly
                                                                   placeholder="Select Article"
                                                                   onClick={() => showDrawer(index, 'missingCitation', 'citedArticle')}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'title']}
                                                            label='Title'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Paper Pdf</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'missingCitation')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'missingCitation')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}

                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addMissingCitation()}
                        icon={<PlusOutlined/>}
                    >
                        Add Missing Citation
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Highlighted Comments from Reviewers of Your Publications</Title>
            <p>Outstanding Reviewer Recognition</p>
            <Divider/>
            <Form.List name="highlightComments">
                {(fields, {add, remove}) => {
                    addHighlightComments = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "highlightComments", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Highlighted Comments-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'citedArticle']}
                                                            label='Cited Article'
                                                        >
                                                            <Input readOnly
                                                                   placeholder="Select Article"
                                                                   onClick={() => showDrawer(index, 'highlightComments', 'citedArticle')}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'comment']}
                                                            label='Comments'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'employment')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'employment')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}

                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addHighlightComments()}
                        icon={<PlusOutlined/>}
                    >
                        Add Highlighted Comments
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Books</Title>
            <Divider/>
            <Form.List name="books">
                {(fields, {add, remove}) => {
                    addBooks = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "books", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Books-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'name']}
                                                            label='Book Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'publisher']}
                                                            label='Publisher'
                                                        >
                                                            <Input.TextArea rows={2}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'brief']}
                                                            label='Brief Intro'
                                                        >
                                                            <Input.TextArea rows={6}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                            <br/>
                                            <Title level={4}>The Impact of Your Book</Title>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'impact']}
                                                            label='Impact'
                                                        >
                                                            <Input.TextArea rows={6}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'books')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'books')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addBooks()}
                        icon={<PlusOutlined/>}
                    >
                        Add Books
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Patent</Title>
            <Divider/>
            <Form.List name="patent">
                {(fields, {add, remove}) => {
                    addPatent = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "patent", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Patent-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'number']}
                                                            label='Number'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'title']}
                                                            label='Title'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'intro']}
                                                            label='Short Intro'
                                                        >
                                                            <Input.TextArea rows={6}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'status']}
                                                            label='Status'
                                                        >
                                                            <Select >
                                                                <Option value="0">Provisional</Option>
                                                                <Option value="1">Issued</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                            <br />
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item name={[name, 'licenseStatus']} valuePropName="unchecked">
                                                        <Checkbox style={{fontSize: 15, fontWeight: "bolder"}}>Licensing Status</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[name, 'licensee']}
                                                            label='Licensee'
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[name, 'licensor']}
                                                            label='Licensor'
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[name, 'summary']}
                                                            label={<Tooltip title={'If licensed for commercial use'}>Summary Of Licensed Application</Tooltip>}
                                                        >
                                                            <Input.TextArea rows={3}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'patent')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'patent')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addPatent()}
                        icon={<PlusOutlined/>}
                    >
                        Add Patent
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Funding Award</Title>
            <Divider/>
            <Form.List name="fundingAward">
                {(fields, {add, remove}) => {
                    addFundingAward = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "fundingAward", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Funding Award-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'title']}
                                                            label='Title'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'agencies']}
                                                            label={<Tooltip title={'e.g.: NSF, DHS, etc.'}>Funding Agencies</Tooltip>}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'role']}
                                                            label={<Tooltip title={'PI, co-PI, or senior Personnel'}>Role</Tooltip>}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'amount']}
                                                            label={<Tooltip title={'USD'}>Amount</Tooltip>}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'intro']}
                                                            label='Brief Intro'
                                                        >
                                                            <Input.TextArea rows={3} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'awardDate']}
                                                            label='Award Date'
                                                        >
                                                            <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'fundingAward')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'fundingAward')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addFundingAward()}
                        icon={<PlusOutlined/>}
                    >
                        Add Funding Award
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Honors</Title>
            <Divider/>
            <Form.List name="honors">
                {(fields, {add, remove}) => {
                    addHonors = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "honors", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Honors-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'prizeName']}
                                                            label='Honor Prize Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'prizeIntro']}
                                                            label='Brief Intro Of Honor Or Prize'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'organization']}
                                                            label='Organization'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'OrganizationIntro']}
                                                            label='Brief Intro Of Organization'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'why']}
                                                            label='Why You Won'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'receiveDate']}
                                                            label='Received Date'
                                                        >
                                                            <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Organization Proof</Title>
                                            <UploadFileBox/>
                                            <br/>
                                            <Title level={5}>Honor Proof</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'honors')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'honors')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addHonors()}
                        icon={<PlusOutlined/>}
                    >
                        Add Honor
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Media Reports</Title>
            <Divider/>
            <Form.List name="mediaReports">
                {(fields, {add, remove}) => {
                    addMediaReports = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "mediaReports", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Media Reports-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'mediaName']}
                                                            label='Media Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'mediaRanking']}
                                                            label='Media Ranking'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'paper']}
                                                            label={<Tooltip title={'Does it mention your research? if yes, which paper'}>Mentioned Research Paper</Tooltip>}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'reportContent']}
                                                            label='Report Content'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Proof Of Ranking</Title>
                                            <UploadFileBox/>
                                            <br/>
                                            <Title level={5}>Proof of Media Report</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'mediaReports')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'mediaReports')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addMediaReports()}
                        icon={<PlusOutlined/>}
                    >
                        Add Media Reports
                    </Button>
                </Form.Item>
            </div>

            <Title level={4}>Presentations</Title>
            <Divider/>
            <Form.List name="presentations">
                {(fields, {add, remove}) => {
                    addPresentations = add;
                    const customRemove = ( name, index) => {
                        remove(name);
                        removeCurrentFormItem(form, "presentations", index)
                    }
                    return (
                        <>
                            {fields.map(({key, name, ...restField}, index) => (
                                <div className={'edu-history'} key={index}>
                                    <Title level={5}>*Presentations-{index + 1}</Title>
                                    <Space align={'start'}>
                                        <div>
                                            <div className={'edu-history-item'}>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'presentationType']}
                                                            label='Presentation Type'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'presentationTitle']}
                                                            label='Presentation Title'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'conferenceName']}
                                                            label='Conference Name'
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'conferenceIntro']}
                                                            label='Intro of the Conference'
                                                        >
                                                            <Input.TextArea rows={4}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'receiveDate']}
                                                            label='Received Date'
                                                        >
                                                            <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Title level={5}>Conference Letter</Title>
                                            <UploadFileBox/>
                                            <br />
                                            <Title level={5}>Schedule Letter</Title>
                                            <UploadFileBox/>
                                        </div>
                                        <Space direction={"vertical"}>
                                            {fields.length > 1 && <Space direction={"vertical"}>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, -1, fields, 'presentations')}>Up</Button>
                                                <Button type="dashed"
                                                        onClick={() => moveItem(form, index, 1, fields, 'presentations')}>Down</Button>
                                            </Space>}
                                            <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                        </Space>
                                    </Space>
                                </div>
                            ))}
                        </>
                    )
                }}
            </Form.List>
            <div style={{marginTop: 10}}>
                <Form.Item>
                    <Button
                        onClick={() => addPresentations()}
                        icon={<PlusOutlined/>}
                    >
                        Add Presentations
                    </Button>
                </Form.Item>
            </div>


            <Drawer
                destroyOnClose={true}
                placement={'bottom'}
                closable={false}
                onClose={closeDrawer}
                open={open}
                height={600}
            >
                <ScholarPapersTable handleSelect={handleSelect}/>
            </Drawer>

        </div>
    )
}

export default ResearchAwards;